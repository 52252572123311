import React from "react";
import DisplayCheckboxList from "../components/DisplayCheckboxList";
import {City, EquipmentType, StateData} from "../constants/DataInterfaces";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import {connect} from "react-redux";
import {Button, Card, CardContent, Typography} from "@material-ui/core";
import DropDownComponent from "../components/DropDownComponent";
import {convertMapToObject, getValidCitiesForState} from "../constants/utilityFunctions";

interface Props {
    cityIdToDataMap: Map<number, City>;
    stateIdToDataMap: Map<number, StateData>;
}

interface State {
    stateId: number;
    cityId: number;
    selectedItems: Set<string>;

    validCities: { [key: string]: string };
}

class SearchOnSocialMedia extends React.Component<Props, State> {

    private readonly stateMap: { [key: number]: StateData };

    constructor(props) {
        super(props);
        this.state = {
            stateId: null,
            selectedItems: new Set(),
            cityId: null,
            validCities: {}
        };
        this.stateMap = convertMapToObject(this.props.stateIdToDataMap);
        this.generateTwitterQuery = this.generateTwitterQuery.bind(this);
    }

    async getCitiesForState(stateId: number) {
        this.setState({
            cityId: null,
            stateId: stateId
        });

        this.setState({
            validCities: getValidCitiesForState(stateId, this.props.cityIdToDataMap)
        });
    }

    generateTwitterQuery() {
        const {cityId, stateId, selectedItems} = this.state;
        if (cityId == null && stateId == null) {
            alert("Please select a state or a city!");
            return;
        }

        if (selectedItems.size === 0) {
            alert("Please select a service!");
            return;
        }

        let placeName: string = cityId == null ? this.stateMap[stateId].name : this.props.cityIdToDataMap.get(cityId).name;
        if (placeName.indexOf("Kanpur") != -1) {
            placeName = "Kanpur";
        }

        let queryString = `https://twitter.com/search?q=verified ${placeName} (`;
        for (let selectedItem of selectedItems) {
            queryString = `${queryString} ${EquipmentType[selectedItem].toString().toLowerCase()} OR`
        }
        queryString = queryString.slice(0, -2);
        queryString = `${queryString}) -"not verified" -"unverified" -"needed" -"need" -"needs" -"required" -"require" -"requires" -"requirement" -"requirements"`;

        window.open(queryString, "_blank");
    }

    render() {
        return (
            <ResponsiveAppBar title={"Search on Social Media"}>
                <Card>
                    <CardContent>
                        <Typography style={{marginBottom: 10}} variant="h6">
                            Select the state/city and the supplies needed.
                        </Typography>
                        <DisplayCheckboxList
                            checkBoxKeys={Object.keys(EquipmentType)}
                            checkBoxValues={Object.values(EquipmentType)}
                            checkedKeys={this.state.selectedItems}
                            handleChange={(key: string) => {
                                const selectedItems = new Set(this.state.selectedItems);
                                if (selectedItems.has(key)) {
                                    selectedItems.delete(key);
                                } else {
                                    selectedItems.add(key);
                                }
                                this.setState({
                                    selectedItems: selectedItems
                                });
                            }}
                        />
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <DropDownComponent
                                disabled={false}
                                onChange={value => this.getCitiesForState(parseInt(value))}
                                selectedValue={this.state.stateId}
                                title={"Select State"}
                                dropDownKeys={Object.keys(this.stateMap)}
                                dropDownValues={Object.values(this.stateMap).map(val => val.name)}
                            />
                            <DropDownComponent
                                disabled={this.state.stateId == null}
                                onChange={value => this.setState({cityId: parseInt(value)})}
                                selectedValue={this.state.cityId}
                                title={"Select City"}
                                dropDownKeys={Object.keys(this.state.validCities)}
                                dropDownValues={Object.values(this.state.validCities)}
                            />
                        </div>
                        <Button color={"primary"} onClick={this.generateTwitterQuery}>
                            Search On Twitter
                        </Button>
                    </CardContent>
                </Card>
            </ResponsiveAppBar>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        cityIdToDataMap: state.rootReducer.cityIdToDataMap,
        stateIdToDataMap: state.rootReducer.stateIdToDataMap
    };
}

export default connect(mapStateToProps, null)(SearchOnSocialMedia);
