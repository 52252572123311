import React from "react";
import {Checkbox, FormControl, FormControlLabel} from "@material-ui/core";

interface Props {
    checkBoxKeys: Array<string>;
    checkBoxValues: Array<string>;
    checkedKeys: Set<string>;
    handleChange: (key: string) => void;
}

export default class DisplayCheckboxList extends React.Component<Props, any> {


    constructor(props) {
        super(props);
    }


    render() {
        const {checkBoxKeys, checkBoxValues, checkedKeys, handleChange} = this.props;
        return (
            <FormControl component="fieldset"
                         style={{
                             display: 'flex',
                             overflowX: 'scroll',
                             flexDirection: 'column',
                             flexWrap: 'wrap',
                             alignContent: 'flex-start',
                             height: 250
                         }}>
                {checkBoxKeys.map((key, index) => {
                    return (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={key}
                                    checked={checkedKeys.has(key)}
                                    onChange={event => handleChange(key)}
                                    name="gilad"/>
                            }
                            label={checkBoxValues[index]}
                        />
                    );
                })}
            </FormControl>
        );
    }

}
