import * as React from "react";
import {connect} from "react-redux";
import AvailabilityRequirementsTable from "../components/AvailabilityRequirementTable"
import {
    AvailabilityData,
    RequirementData,
    ResourceType,
    User
} from "../constants/DataInterfaces";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import Typography from "@material-ui/core/Typography";
import {filterResources} from "../constants/utilityFunctions";

interface Props {
    user: User;
    availabilityData: AvailabilityData[];
    requirementsData: RequirementData[];
}

interface State {
}

class RequirementsPage extends React.Component<Props, State> {

    render() {
        const requirementDataForUser: RequirementData[] = this.props.requirementsData.filter((datum: RequirementData) => {
            return this.props.user != null && datum.userId === this.props.user.id;
        });

        const matchingAvailability: AvailabilityData[] = filterResources(this.props.availabilityData, requirementDataForUser);

        return (
            <ResponsiveAppBar title={"My Requirements"}>
                <AvailabilityRequirementsTable
                    resourceType={ResourceType.REQUIREMENT}
                    buttonText={"Add Requirement for Equipment"}
                    availabilityData={requirementDataForUser}/>
                {matchingAvailability.length != 0 &&
                <>
                    <Typography style={{textAlign: "center", marginTop: 50, marginBottom: 10}} component="h6" variant="h6">
                        Matching Availability
                    </Typography>
                    <AvailabilityRequirementsTable
                        resourceType={ResourceType.AVAILABILITY}
                        buttonText={null}
                        availabilityData={matchingAvailability}/>
                </>}
            </ResponsiveAppBar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        availabilityData: state.rootReducer.availabilityData,
        requirementsData: state.rootReducer.requirementsData,
        user: state.rootReducer.user
    };
}

export default connect(mapStateToProps, null)(RequirementsPage);
