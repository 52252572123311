import * as React from "react";
import {connect} from "react-redux";
import AvailabilityRequirementsTable from "../components/AvailabilityRequirementTable"
import {
    AvailabilityData,
    RequirementData,
    ResourceType,
    User
} from "../constants/DataInterfaces";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import AuthPage from "../components/AuthPage";
import Typography from "@material-ui/core/Typography";
import {filterResources} from "../constants/utilityFunctions";

interface Props {
    user: User;
    availabilityData: AvailabilityData[];
    requirementsData: RequirementData[];
}

interface State {

}

class MyAvailabilityPage extends React.Component<Props, State> {

    render() {

        const availabilityDataForUser: AvailabilityData[] = this.props.availabilityData.filter((datum: AvailabilityData) => {
            return this.props.user != null && datum.userId === this.props.user.id;
        });

        const matchingRequirements: RequirementData[] = filterResources(this.props.requirementsData, availabilityDataForUser) as RequirementData[];

        return (
            <ResponsiveAppBar title={"My Availability"}>
                <AuthPage>
                    <AvailabilityRequirementsTable
                        resourceType={ResourceType.AVAILABILITY}
                        buttonText={"Add Availability for Equipment"}
                        availabilityData={availabilityDataForUser}/>
                    {matchingRequirements.length != 0 &&
                    <>
                        <Typography style={{textAlign: "center", marginTop: 50, marginBottom: 10}} component="h6" variant="h6">
                            Matching Requirements
                        </Typography>
                        <AvailabilityRequirementsTable
                            resourceType={ResourceType.REQUIREMENT}
                            buttonText={null}
                            availabilityData={matchingRequirements}/>
                    </>}
                </AuthPage>
            </ResponsiveAppBar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        availabilityData: state.rootReducer.availabilityData,
        requirementsData: state.rootReducer.requirementsData,
        user: state.rootReducer.user
    };
}

export default connect(mapStateToProps, null)(MyAvailabilityPage);
