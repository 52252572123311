import axios from 'axios';
import {getCurrentUserInfo} from "./utilityFunctions";

// const apiUrl = "http://127.0.0.1:8080/"
const apiUrl = "https://radiant-earth-69974.herokuapp.com/"

const axiosAuthenticated = axios.create({
    baseURL: apiUrl
});

const axiosUnAuthenticated = axios.create({
    baseURL: apiUrl
});

axiosAuthenticated.interceptors.request.use(async function (config) {
    config.headers.Authorization = (await getCurrentUserInfo()).token;
    return config;
});

export const authenticatedAxios = axiosAuthenticated;
export const unAuthenticatedAxios = axiosUnAuthenticated;
