import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {
    AgeGroup,
    AvailabilityData,
    City,
    EquipmentType, ModalMode,
    RequirementData,
    ResourceType,
    StateData
} from "../constants/DataInterfaces";
import DropDownComponent from "./DropDownComponent";
import {connect} from "react-redux";
import {convertMapToObject, getValidCitiesForState} from "../constants/utilityFunctions";
import LoadingComponent from "./LoadingComponent";
import TextField from "@material-ui/core/TextField";

interface Props {
    open: boolean;
    title: string;
    requirementData: RequirementData;
    resourceType: ResourceType;
    modalMode: ModalMode;

    updateData: (key: keyof RequirementData, value: any) => Promise<void>;
    closeModal: () => void;
    onSubmit: (resourceData: AvailabilityData) => Promise<boolean>;

    cityIdToDataMap: Map<number, City>;
    stateIdToDataMap: Map<number, StateData>;
}

interface State {
    loading: boolean;
    validCities: { [key: string]: string };
}


class AvailabilityRequirementDialog extends React.Component<Props, State> {
    private readonly stateMap: { [key: number]: StateData };

    constructor(props) {
        super(props);
        this.state = {
            validCities: {},
            loading: false
        };
        this.stateMap = convertMapToObject(this.props.stateIdToDataMap);
        this.getCitiesForState = this.getCitiesForState.bind(this);
        this.closeModalAndSaveToBackend = this.closeModalAndSaveToBackend.bind(this);
    }

    async getCitiesForState(stateId: number) {
        await this.props.updateData("cityId", null);

        await this.props.updateData("stateId", stateId);
        this.setState({
            validCities: getValidCitiesForState(stateId, this.props.cityIdToDataMap)
        });
    }

    async closeModalAndSaveToBackend() {
        this.setState({loading: true});
        const {requirementData} = this.props;
        try {
            const success: boolean = await this.props.onSubmit(requirementData);
            if (!success) {
                alert("Please fill the complete form!");
            }
        } catch (error) {
            console.log(error);
            alert(error.message);
        }
        this.setState({loading: false});
        this.props.closeModal();
    }

    render() {
        const {resourceType, closeModal, requirementData, updateData} = this.props;
        return (
            <Dialog
                onBackdropClick={closeModal}
                onClose={closeModal}
                open={this.props.open}
                aria-labelledby="responsive-dialog-title">
                <LoadingComponent showLoading={this.state.loading}/>
                <DialogContent style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <DialogTitle id="responsive-dialog-title">{this.props.title}</DialogTitle>
                    <DropDownComponent
                        disabled={false}
                        onChange={value => updateData("equipmentType", value)}
                        selectedValue={requirementData.equipmentType}
                        title={"Equipment/Service"}
                        dropDownKeys={Object.keys(EquipmentType)}
                        dropDownValues={Object.values(EquipmentType)}
                    />
                    {resourceType == ResourceType.REQUIREMENT && <DropDownComponent
                        disabled={false}
                        onChange={value => updateData("ageGroup", value)}
                        selectedValue={(requirementData as RequirementData).ageGroup}
                        title={"Age Group"}
                        dropDownKeys={Object.keys(AgeGroup)}
                        dropDownValues={Object.values(AgeGroup)}
                    />}
                    <DropDownComponent
                        disabled={false}
                        onChange={value => this.getCitiesForState(parseInt(value))}
                        selectedValue={this.props.requirementData.stateId}
                        title={"Select State"}
                        dropDownKeys={Object.keys(this.stateMap)}
                        dropDownValues={Object.values(this.stateMap).map(val => val.name)}
                    />
                    <DropDownComponent
                        disabled={this.props.requirementData.stateId == null}
                        onChange={value => updateData("cityId", parseInt(value))}
                        selectedValue={requirementData.cityId}
                        title={"Select City"}
                        dropDownKeys={Object.keys(this.state.validCities)}
                        dropDownValues={Object.values(this.state.validCities)}
                    />
                    {this.props.modalMode !== ModalMode.FILTERING &&
                    <TextField
                        onChange={(event) => {
                            const quantity: number = parseInt(event.target.value);
                            if (quantity < 1) {
                                alert("Quantity must be greater than 0");
                                return;
                            }
                            updateData("quantity", quantity);
                        }}
                        value={this.props.requirementData.quantity}
                        fullWidth
                        type={"number"}
                        id="quantity"
                        label="Quantity"
                        name="quantity"
                    />}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={closeModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.closeModalAndSaveToBackend} color="primary" autoFocus>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        cityIdToDataMap: state.rootReducer.cityIdToDataMap,
        stateIdToDataMap: state.rootReducer.stateIdToDataMap
    };
}

export default connect(mapStateToProps, null)(AvailabilityRequirementDialog);
