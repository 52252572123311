import * as React from "react";
import {withRouter} from "react-router-dom";
import LoadingComponent from "./LoadingComponent";
import {connect} from "react-redux";
import {User} from "../constants/DataInterfaces";

interface Props {
    children: any[] | any;
    history: any;

    user: User;
}

interface State {
    loading: boolean;
}

class AuthPage extends React.Component<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };
    }


    async componentDidMount() {
        if(this.props.user == null) {
            alert("You need to login before accessing this page!");
            this.props.history.push({
                pathname: "/login/"
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    render() {
        return this.state.loading
            ? <LoadingComponent showLoading={this.state.loading}/>
            : this.props.children;
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.rootReducer.user
    };
}

export default connect(mapStateToProps, null)(withRouter(AuthPage));
