import * as React from "react";
import {connect} from "react-redux";
import AvailabilityRequirementsTable from "../components/AvailabilityRequirementTable"
import {AvailabilityData, ResourceType} from "../constants/DataInterfaces";
import ResponsiveAppBar from "../components/ResponsiveAppBar";

interface Props {
    availabilityData: AvailabilityData[];
}

interface State {
}

class AvailabilityPage extends React.Component<Props, State> {

    render() {
        return (
            <ResponsiveAppBar title={"Availability"}>
                <AvailabilityRequirementsTable
                    resourceType={ResourceType.AVAILABILITY}
                    buttonText={"Add Availability for Equipment"}
                    availabilityData={this.props.availabilityData}/>
            </ResponsiveAppBar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        availabilityData: state.rootReducer.availabilityData,
    };
}

export default connect(mapStateToProps, null)(AvailabilityPage);
