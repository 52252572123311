import React from "react";
import {Button} from "@material-ui/core";
import AvailabilityRequirementDialog from "./AvailabilityRequirementDialog";
import {getCurrentUserInfo, initialiseEmptyResourceData} from "../constants/utilityFunctions";
import {AvailabilityData, ModalMode, RequirementData, ResourceType} from "../constants/DataInterfaces";
import {withRouter} from "react-router-dom";

interface Props {
    buttonText: string;
    resourceType: ResourceType;

    history: any;

    postResourceRequest: (data: AvailabilityData, resourceType: ResourceType) => Promise<void>;
}

interface State {
    showModal: boolean;
    newResourceData: AvailabilityData;
}

class ButtonAndNewDataModal extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            newResourceData: initialiseEmptyResourceData()
        };
        this.redirectToLoginIfUnauthorized = this.redirectToLoginIfUnauthorized.bind(this);
    }

    async redirectToLoginIfUnauthorized() {
        try {
            await getCurrentUserInfo();
            this.setState({showModal: true});
        } catch (error) {
            alert("You need to login before posting.");
            this.props.history.push({
                pathname: "/login"
            });
        }
    }


    render() {
        const {buttonText, resourceType} = this.props;
        const {showModal} = this.state;
        return (
            <div>
                {buttonText != null && <Button
                    style={{marginBottom: 15}}
                    variant="contained"
                    onClick={this.redirectToLoginIfUnauthorized}>{buttonText}</Button>}
                <AvailabilityRequirementDialog
                    modalMode={ModalMode.NEW_RESOURCE}
                    resourceType={resourceType}
                    open={showModal}
                    closeModal={() => {
                        this.setState({
                            newResourceData: initialiseEmptyResourceData(),
                            showModal: false
                        });
                    }}
                    onSubmit={async (resourceData: AvailabilityData) =>  {
                        if (resourceData.cityId == null || resourceData.equipmentType == null || resourceData.quantity < 1) {
                            alert("Please fill the complete form!");
                            return false;
                        }
                        if (resourceType == ResourceType.REQUIREMENT && (resourceData as RequirementData).ageGroup == null) {
                            alert("Please fill the complete form!");
                            return false;
                        }
                        await this.props.postResourceRequest(resourceData, resourceType);
                        return true;
                    }}
                    requirementData={this.state.newResourceData as RequirementData}
                    title={`${resourceType == ResourceType.REQUIREMENT ? "Request" : "Post"} 
                    the equipment/service you need.`}
                    updateData={async (key, value) => {
                        const newAvailabilityData = {...this.state.newResourceData};
                        newAvailabilityData[key] = value;
                        this.setState({
                            newResourceData: newAvailabilityData
                        });
                    }}
                />
            </div>
        );
    }
}

export default (withRouter(ButtonAndNewDataModal));

