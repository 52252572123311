import * as React from "react";
import {connect} from "react-redux";
import AvailabilityRequirementsTable from "../components/AvailabilityRequirementTable"
import {RequirementData, ResourceType} from "../constants/DataInterfaces";
import ResponsiveAppBar from "../components/ResponsiveAppBar";

interface Props {
    requirementsData: RequirementData[];
}

interface State {

}

class RequirementsPage extends React.Component<Props, State> {

    render() {
        return (
            <ResponsiveAppBar title={"Requirements"}>
                <AvailabilityRequirementsTable
                    resourceType={ResourceType.REQUIREMENT}
                    buttonText={"Add Requirement for Equipment"}
                    availabilityData={this.props.requirementsData}/>
            </ResponsiveAppBar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        requirementsData: state.rootReducer.requirementsData
    };
}

export default connect(mapStateToProps, null)(RequirementsPage);
