import {Auth} from 'aws-amplify';

const authConfig = {
    region: "ap-southeast-1",
    userPoolWebClientId: "1b8600s0ro3lbuodmnc0to79vk",
    userPoolId: "ap-southeast-1_ZVQEvEhd6"
};


Auth.configure({
    region: authConfig.region,
    userPoolWebClientId: authConfig.userPoolWebClientId,
    userPoolId: authConfig.userPoolId,
});
