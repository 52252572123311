import React from "react";
import {FormControl, InputLabel, MenuItem, Select, withStyles} from "@material-ui/core";
import {Theme} from "@material-ui/core/styles";

interface Props {
    classes: any;

    dropDownKeys: Array<string | number>;
    dropDownValues: Array<string>;
    title: string;
    selectedValue: any;
    disabled: boolean;

    onChange: (value: any) => void;

}

interface State {

}

const styles = (theme: Theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: "100%"
    },
});

class DropDownComponent extends React.Component<Props, State> {

    render() {
        const {
            classes,
            dropDownKeys,
            disabled,
            dropDownValues,
            title,
            onChange,
            selectedValue
        } = this.props;
        return (
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">{title}</InputLabel>
                <Select
                    disabled={disabled}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedValue}
                    onChange={(event) => {
                        onChange(event.target.value)
                    }}>

                    {dropDownKeys.map((key, index) => {
                        return (
                            <MenuItem style={{color: "black"}} value={key}>
                                {dropDownValues[index]}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    }
}

export default withStyles(styles)(DropDownComponent);
