import reducer from "./reducer";
import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk';
import {composeWithDevTools} from "redux-devtools-extension";

const middlewares = [];
middlewares.push(thunk);
const reducers = combineReducers({
        rootReducer: reducer
});

export default createStore(reducers, composeWithDevTools(
    applyMiddleware(...middlewares)
));
