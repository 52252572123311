import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {User} from "../constants/DataInterfaces";
import {connect} from "react-redux";
import {Button, withStyles} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import {signOut} from "../constants/backend_api_action";
import SideMenuBar from "./SideMenuBar";


const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: "5%",
        [theme.breakpoints.up('sm')]: {
            padding: "2%",
        },
    },
});

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    classes: any;
    history: any;

    title: string;
    children: any[] | any;

    user: User;
    signOut: () => Promise<void>;
}

interface State {
    mobileOpen: boolean;
}

class ResponsiveAppBar extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            mobileOpen: false
        };

        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
        this.handleAuthButtonClick = this.handleAuthButtonClick.bind(this);
    }

    handleDrawerToggle() {
        this.setState({
            mobileOpen: !this.state.mobileOpen
        });
    }

    async handleAuthButtonClick() {
        if (this.props.user == null) {
            this.props.history.push({
                pathname: "/login/"
            });
        } else {
            try {
                await this.props.signOut();
                this.props.history.push({
                    pathname: "/"
                });
            } catch (error) {
                alert(error.message);
            }
        }
    }

    render() {
        const {window, classes} = this.props;
        const container = window !== undefined ? () => window().document.body : undefined;
        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={this.handleDrawerToggle}
                            className={classes.menuButton}>
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6" style={{flexGrow: 1}}>
                            {this.props.title}
                        </Typography>
                        <Button onClick={this.handleAuthButtonClick} color="inherit">
                            {this.props.user == null ? "Login" : "Logout"}
                        </Button>
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer} aria-label="mailbox folders">
                    <Hidden smUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={'left'}
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}>
                            <SideMenuBar user={this.props.user} history={this.props.history} />
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open>
                            <SideMenuBar user={this.props.user} history={this.props.history} />
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={classes.content}>
                    <Toolbar/>
                    {this.props.children}
                </main>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.rootReducer.user
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => {
            return dispatch(signOut());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ResponsiveAppBar)));
