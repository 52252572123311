import {Auth} from "aws-amplify";
import {AvailabilityData, City, RequirementData, ResourceType, SideBarOption} from "./DataInterfaces";

export function validateEmail(email: string): boolean {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        return true;
    }
    alert("You have entered an invalid email address!");
    return false;
}

export function validatePassword(password: string): boolean {
    if (password != null && password.length > 8) {
        return true;
    }
    alert("Password cannot be empty or less than 8 characters!");
    return false;
}

export function matchPasswords(password: string, confirmPassword: string): boolean {
    if (password === confirmPassword) {
        return true;
    }
    alert("Passwords do not match!");
    return false;
}

export function validatePhoneNumber(phoneNumber: number): boolean {
    if (phoneNumber == null) {
        alert("Phone number cannot be empty!");
        return false;
    }

    if (phoneNumber.toString().length !== 10) {
        alert("Invalid phone number!");
        return false;
    }
    return true;
}

export async function getCurrentUserInfo(): Promise<{ token: string, userId: string }> {
    let user = await getCurrentUser();
    return {
        token: "Bearer " + user.getSignInUserSession().getIdToken().getJwtToken(),
        userId: user.getUsername(),
    };
}

export async function getCurrentUser(): Promise<any> {
    await Auth.currentSession();
    return await Auth.currentAuthenticatedUser();
}

export function initialiseEmptyResourceData(): AvailabilityData {
    return {
        id: null,
        userId: null,
        equipmentType: null,
        cityId: null,
        stateId: null,
        quantity: 1,
        description: null
    };
}

export function getSideBarOptions(hasAuth: boolean): SideBarOption[] {
    const sideBarOptions: SideBarOption[] = [{
        title: "Requirements",
        route: "/requirements/",
        active: true
    }, {
        title: "Availability",
        route: "/availability/",
        active: true
    },{
        title: "Search on Social Media",
        route: "/search-on-social-media/",
        active: true
    }];

    if (hasAuth) {
        sideBarOptions.push({
            title: "My Requirements",
            route: "/my-requirements/",
            active: true
        });
        sideBarOptions.push({
            title: "My Availability",
            route: "/my-availability/",
            active: true
        });
    }

    return sideBarOptions;
}

export function convertMapToObject(map: Map<any, any>): { [key: string]: any } {
    const object: { [key: string]: any } = {};

    for (let [key, value] of map) {
        object[key] = value;
    }

    return object;
}

export function filterResources(resources: AvailabilityData[], filters: AvailabilityData[]): AvailabilityData[] {
    const filteredResources: AvailabilityData[] = [];
    for (const resource of resources) {
        let matchFound: boolean = false;
        for (const filter of filters) {
            if (matchResourceWithFilter(resource, filter)) {
                matchFound = true;
                break;
            }
        }
        if (matchFound) {
            filteredResources.push(resource);
            break;
        }
    }
    return filteredResources;
}

export function matchResourceWithFilter(data: AvailabilityData, filterCriteria: AvailabilityData) {
    const resourceType: ResourceType = (data as RequirementData).ageGroup != null && (filterCriteria as RequirementData).ageGroup != null
        ? ResourceType.REQUIREMENT : ResourceType.AVAILABILITY;
    return (filterCriteria.equipmentType == null || filterCriteria.equipmentType == data.equipmentType)
        && (filterCriteria.cityId == null || filterCriteria.cityId == data.cityId)
        && (filterCriteria.stateId == null || filterCriteria.stateId == data.stateId)
        && (resourceType == ResourceType.AVAILABILITY
            || (filterCriteria as RequirementData).ageGroup == null
            || (filterCriteria as RequirementData).ageGroup == (data as RequirementData).ageGroup)
}

export function getValidCitiesForState(stateId: number, cityIdToDataMap: Map<number, City>): { [key: string]: string } {

    const validCities: { [key: string]: string } = {}
    for (let [key, value] of cityIdToDataMap) {
        if (value.stateId == (stateId)) {
            validCities[key] = value.name;
        }
    }

    return validCities;
}
