import React from "react";
import {Button, IconButton, Toolbar, Tooltip} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import {withStyles} from "@material-ui/core";
import {lighten, Theme} from "@material-ui/core/styles";
import {AvailabilityData, ModalMode, RequirementData, ResourceType} from "../constants/DataInterfaces";
import AvailabilityRequirementDialog from "./AvailabilityRequirementDialog";
import {initialiseEmptyResourceData} from "../constants/utilityFunctions";

const styles = (theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            }
});

interface Props {
    classes: any;
    resourceType: ResourceType;
    filterCriteria: AvailabilityData;

    setFilterCriteria: (filterCriteria: AvailabilityData) => void;
}

interface State {
    showModal: boolean;
}

class TableToolbar extends React.Component<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        };
    }

    render() {
        const {classes, resourceType, filterCriteria} = this.props;
        const {showModal} = this.state;
        return (
            <Toolbar className={classes.root}>
                <Button
                    color="primary"
                    onClick={() => {
                        this.props.setFilterCriteria(initialiseEmptyResourceData());
                    }}>
                    Clear Filters
                </Button>
                <Tooltip
                    title="Filter list">
                    <IconButton
                        onClick={() => {
                            this.setState({
                                showModal: true
                            });
                        }}
                        aria-label="filter list">
                        <FilterListIcon/>
                    </IconButton>
                </Tooltip>
                <AvailabilityRequirementDialog
                    modalMode={ModalMode.FILTERING}
                    resourceType={resourceType}
                    open={showModal}
                    closeModal={() => {
                        this.setState({
                            showModal: false
                        });
                    }}
                    onSubmit={async () => {
                        return Promise.resolve(true);
                    }}
                    requirementData={filterCriteria as RequirementData}
                    title={"Select filtering criteria"}
                    updateData={async (key, value) => {
                        const newFilterCriteria = {...filterCriteria};
                        newFilterCriteria[key] = value;
                        await this.props.setFilterCriteria(newFilterCriteria);
                    }}
                />
            </Toolbar>
        );
    }
}

export default withStyles(styles as any)(TableToolbar);