import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";

interface State {
    showModal: boolean;
}

export default class HowToUseDialog extends React.Component<any, State> {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.doNotShowAgain = this.doNotShowAgain.bind(this);
    }

    componentDidMount() {
        const showHowToUseDialog = localStorage.getItem('showHowToUseDialog');
        if (showHowToUseDialog == null) {
            this.setState({
                showModal: true
            });
        }
    }

    toggleModal() {
        this.setState({
            showModal: false
        });
    }

    doNotShowAgain() {
        localStorage.setItem('showHowToUseDialog', "true");
        this.toggleModal();
    }


    render() {
        return (
            <Dialog
                onBackdropClick={this.toggleModal}
                onClose={this.toggleModal}
                open={this.state.showModal}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle
                    id="responsive-dialog-title">{"How To Use Covid Help Tool"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        1.) If you have supplies available, visit the Availability section to post the available supplies.
                    </DialogContentText>
                    <DialogContentText>
                        2.) First check the Availability section if you are in need of supplies. If you do not find the needed supplies, visit the Requirement section to post your needs.
                    </DialogContentText>
                    <DialogContentText>
                        3.) You can view Availability/Requirements without logging in. However, you must be logged in to post anything in either of the sections.
                    </DialogContentText>
                    <DialogContentText>
                        4.) Once logged in, you can see your postings under My Requirements and My
                        Availability.
                    </DialogContentText>
                    <DialogContentText>
                        5.) You need to remove your requirement/availability once it has been satisfied/availed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.toggleModal} color="primary">
                        Ok
                    </Button>
                    <Button onClick={this.doNotShowAgain} color="primary">
                        Do Not Show Again
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
