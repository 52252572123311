import React, {useEffect} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import {
    AgeGroup,
    AvailabilityData,
    City,
    EquipmentType,
    RequirementData,
    ResourceType,
    StateData,
    User
} from "../constants/DataInterfaces";
import ButtonAndNewDataModal from "./ButtonAndNewDataModal";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import {IconButton, TablePagination} from "@material-ui/core";
import {deleteResource, postResourceRequest} from "../constants/backend_api_action";
import {connect} from "react-redux";
import TableToolbar from "./TableToolbar";
import {initialiseEmptyResourceData, matchResourceWithFilter} from "../constants/utilityFunctions";

interface RowData {
    id: string;
    equipmentType: EquipmentType;
    phoneNumber: number;
    email: string;
    city: string;
    state: string;
    quantity: number;
    ageGroup: AgeGroup;
}

function createRowData(availabilityData: AvailabilityData, userData: User, cityData: City, stateData: StateData): RowData {
    return {
        id: availabilityData.id,
        equipmentType: EquipmentType[availabilityData.equipmentType],
        phoneNumber: userData != null ? userData.phoneNumber : 0,
        email: userData != null ? userData.email : "",
        city: cityData != null ? cityData.name : "",
        state: stateData != null ? stateData.name : "",
        quantity: availabilityData.quantity,
        ageGroup: (availabilityData as RequirementData).ageGroup
    };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof RowData;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    {id: 'email', numeric: false, disablePadding: false, label: 'Email Address'},
    {id: 'phoneNumber', numeric: true, disablePadding: false, label: 'Phone Number'},
    {id: 'equipmentType', numeric: true, disablePadding: false, label: 'Equipment Type'},
    {id: 'city', numeric: true, disablePadding: false, label: 'City'},
    {id: 'state', numeric: true, disablePadding: false, label: 'State'},
    {id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity'},
    {id: 'ageGroup', numeric: true, disablePadding: false, label: 'Age Group'}
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof RowData) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    resourceType: ResourceType;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {classes, order, orderBy, onRequestSort, resourceType} = props;
    const createSortHandler = (property: keyof RowData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox"/>
                {headCells.map((headCell) => {
                    if (props.resourceType == ResourceType.AVAILABILITY && headCell.id == "ageGroup") {
                        return null;
                    }
                    return (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}>
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span
                                        className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    )}
                )}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: window.innerWidth * 0.9,
            [theme.breakpoints.up('sm')]: {
                maxWidth: window.innerWidth * 0.96,
            },
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        button: {
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(3)
        }
    }),
);

interface Props {
    buttonText: string;
    availabilityData: AvailabilityData[];
    resourceType: ResourceType;

    userIdToDataMap: Map<string, User>;
    cityIdToDataMap: Map<number, City>;
    stateIdToDataMap: Map<number, StateData>;

    deleteResource: (id: string, resourceType: ResourceType) => void;
    postResourceRequest: (data: AvailabilityData, resourceType: ResourceType) => void;
}

function EnhancedTable(props: Props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof RowData>('email');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filterCriteria, setFilterCriteria] = React.useState(initialiseEmptyResourceData());
    const [filteredAvailabilityData, setFilteredAvailabilityData] = React.useState(props.availabilityData);

    useEffect(() => {
        filterRows();
    }, [props.availabilityData, filterCriteria]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof RowData) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const rows: RowData[] = filteredAvailabilityData.map((data) => {
        return createRowData(data, props.userIdToDataMap.get(data.userId), props.cityIdToDataMap.get(data.cityId),
            props.stateIdToDataMap.get(data.stateId));
    });

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filterRows = () => {
        const filteredAvailabilityData: AvailabilityData[] = props.availabilityData.filter((data: AvailabilityData) => {
            return matchResourceWithFilter(data, filterCriteria);
        });
        setFilteredAvailabilityData(filteredAvailabilityData);
    };

    const showDelete = window.location.pathname.indexOf("my-") != -1;

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}
             className={classes.root}>
            <ButtonAndNewDataModal
                postResourceRequest={props.postResourceRequest}
                buttonText={props.buttonText}
                resourceType={props.resourceType}
            />
            <Paper className={classes.paper}>
                <TableToolbar
                    filterCriteria={filterCriteria}
                    setFilterCriteria={(filterCriteria: AvailabilityData) => setFilterCriteria(filterCriteria)}
                    resourceType={props.resourceType}/>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <EnhancedTableHead
                            resourceType={props.resourceType}
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={false}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={false}>
                                            <TableCell padding="checkbox"/>
                                            <TableCell component="th" id={labelId} scope="row"
                                                       padding="checkbox">
                                                {row.email}
                                            </TableCell>
                                            <TableCell align="right">{row.phoneNumber}</TableCell>
                                            <TableCell align="right">{row.equipmentType}</TableCell>
                                            <TableCell align="right">{row.city}</TableCell>
                                            <TableCell align="right">{row.state}</TableCell>
                                            <TableCell align="right">{row.quantity}</TableCell>
                                            {props.resourceType == ResourceType.REQUIREMENT && <TableCell align="right">
                                                {AgeGroup[row.ageGroup]}
                                            </TableCell>}
                                            {showDelete &&
                                            <TableCell
                                                padding="checkbox"
                                                onClick={() => {
                                                    props.deleteResource(row.id.toString(), props.resourceType)
                                                }}>
                                                <IconButton>
                                                    <DeleteOutlinedIcon/>
                                                </IconButton>
                                            </TableCell>}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userIdToDataMap: state.rootReducer.userIdToDataMap,
        cityIdToDataMap: state.rootReducer.cityIdToDataMap,
        stateIdToDataMap: state.rootReducer.stateIdToDataMap
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteResource: (id: string, resourceType: ResourceType) => {
            return dispatch(deleteResource(id, resourceType));
        },
        postResourceRequest: (data: AvailabilityData, resourceType: ResourceType) => {
            return dispatch(postResourceRequest(data, resourceType));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedTable);
