import {Backdrop, CircularProgress, withStyles} from "@material-ui/core";
import React from "react";

interface Props {
    classes: any;
    showLoading: boolean;
}

const useStyles = (theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

class LoadingComponent extends React.Component<Props, any> {

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Backdrop className={classes.backdrop} open={this.props.showLoading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </div>
        );
    }
}

export default withStyles(useStyles)(LoadingComponent);



