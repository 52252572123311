import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./constants/AuthConfig";
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import createStore from './redux/store';
import App from "./App";
import {BrowserRouter as Router} from "react-router-dom";


ReactDOM.render(
    <Provider store={createStore}>
        <Router>
            <App/>
        </Router>   
    </Provider>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
