export interface ReducerState {
    user: User;

    availabilityData: AvailabilityData[];
    requirementsData: RequirementData[];

    userIdToDataMap: Map<string, User>;
    cityIdToDataMap: Map<number, City>;
    stateIdToDataMap: Map<number, StateData>;
}

export enum EquipmentType {
    OXYGEN_CYLINDER = "Oxygen Cylinder",
    MASKS = "Masks",
    BIPAP_MACHINE = "Bipap Machine",
    VENTILATOR = "Ventilator",
    HOSPITAL_BED = "Hospital Bed",
    ICU = "ICU",
    TESTS = "Tests",
    FABIFLU = "Fabiflu",
    REMDESIVIR = "Remdesivir",
    FAVIPIRAVIR = "Faviparivir",
    TOCILIZUMAB = "Tocilizumab",
    AMBULANCE = "Ambulance",
    PLASMA = "Plasma"
}

export enum AgeGroup {
    GROUP_1 = "Under 18",
    GROUP_2 = "18 to 44",
    GROUP_3 = "45 to 60",
    GROUP_4 = "Above 60",
}

export interface SideBarOption {
    title: string;
    route: string;
    active: boolean;
}

export interface User extends SignUpDetails {
    createdAt?: number;
}

export interface SignUpDetails {
    id: string;
    email: string;
    phoneNumber: number;
}

export interface AvailabilityData {
    id: string;
    userId: string;
    equipmentType: EquipmentType;
    cityId: number;
    stateId: number;
    quantity: number;
    description: string;
}

export interface RequirementData extends AvailabilityData {
    ageGroup: AgeGroup;
}

export enum ResourceType {
    AVAILABILITY,
    REQUIREMENT
}

export interface City {
    id: number;
    name: string;
    stateId: number;
}

export interface StateData {
    id: number;
    name: string;
    countryId: number;
}

export enum ModalMode {
    NEW_RESOURCE,
    EDIT_RESOURCE,
    FILTERING
}
