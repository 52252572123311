import {ReducerState} from "../constants/DataInterfaces";
import {
    SET_AVAILABILITY_DATA, SET_CITY_DATA, SET_REQUIREMENTS_DATA, SET_STATE_DATA, SET_USER, SET_USER_DATA
} from "./reducerAction";

const initialState: ReducerState = {
    user: null,
    availabilityData: [],
    requirementsData: [],
    userIdToDataMap: new Map(),
    cityIdToDataMap: new Map(),
    stateIdToDataMap: new Map()
};

export default function reducer(state = initialState, action: {type: string; payload: any}) {
    switch (action.type) {
        case SET_AVAILABILITY_DATA:
            return {
                ...state,
                availabilityData: action.payload
            };
        case SET_REQUIREMENTS_DATA:
            return {
                ...state,
                requirementsData: action.payload
            };
        case SET_USER:
            return {
                ...state,
                user: action.payload
            };
        case SET_USER_DATA:
            return {
                ...state,
                userIdToDataMap: new Map(state.userIdToDataMap.set(action.payload.userId, action.payload.userData))
            };
        case SET_CITY_DATA:
            return {
                ...state,
                cityIdToDataMap: new Map(state.cityIdToDataMap.set(action.payload.cityId, action.payload.cityData))
            };
        case SET_STATE_DATA:
            return {
                ...state,
                stateIdToDataMap: new Map(state.stateIdToDataMap.set(action.payload.stateId, action.payload.stateData))
            };
        default:
            return {
                ...state
            };
    }
};
