import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {withStyles} from "@material-ui/core";
import {validateEmail, validatePassword} from "../constants/utilityFunctions";
import Button from "@material-ui/core/Button";
import LoadingComponent from "../components/LoadingComponent";
import {connect} from "react-redux";
import {signIn} from "../constants/backend_api_action";
import {withRouter} from "react-router-dom";
import CollapsibleAppBar from "../components/CollapsibleAppBar";

interface Props {
    classes: any;
    history: any;

    signIn: (email: string, password: string) => Promise<void>;
}

interface State {
    email: string;
    password: string;
    loading: boolean;
}

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class SignIn extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            email: "",
            password: ""
        };

        this.submitForm = this.submitForm.bind(this);
    }

    async submitForm(event) {
        event.preventDefault();
        if (!(validateEmail(this.state.email) && validatePassword(this.state.password))) {
            return;
        }
        this.setState({
            loading: true
        });

        try {
            await this.props.signIn(this.state.email, this.state.password);
            this.props.history.push({
                pathname: "/"
            });
        } catch (error) {
            alert(error.message);
        }
        this.setState({
            loading: false
        });
    }

    render() {
        const {classes} = this.props;

        return (
            <CollapsibleAppBar title={"Login"}>
                <Container component="main" maxWidth="xs">
                    <LoadingComponent showLoading={this.state.loading}/>
                    <CssBaseline/>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <form className={classes.form} onSubmit={this.submitForm}>
                            <TextField
                                onChange={event => this.setState({
                                    email: event.target.value
                                })}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                onChange={event => this.setState({
                                    password: event.target.value
                                })}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            {/*<FormControlLabel*/}
                            {/*    control={<Checkbox value="remember" color="primary"/>}*/}
                            {/*    label="Remember me"*/}
                            {/*/>*/}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Sign In
                            </Button>
                            <Grid container>
                                {/*<Grid item xs>*/}
                                {/*    <Link href="#" variant="body2">*/}
                                {/*        Forgot password?*/}
                                {/*    </Link>*/}
                                {/*</Grid>*/}
                                <Grid item>
                                    <Link href="/signup/" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Container>
            </CollapsibleAppBar>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (email: string, password: string) => {
            return dispatch(signIn(email, password));
        }
    };
}

export default connect(null, mapDispatchToProps)(withStyles(styles as any)(withRouter(SignIn)));
