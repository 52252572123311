import {
    setAvailabilityData,
    setCityData,
    setRequirementsData,
    setStateData,
    setUser,
    setUserData,
} from "../redux/reducerAction";
import {authenticatedAxios, unAuthenticatedAxios} from "./AxiosConfig";
import {AvailabilityData, City, RequirementData, ResourceType, StateData} from "./DataInterfaces";
import {AxiosResponse} from "axios";
import {Auth} from "aws-amplify";
import {v4 as uuidv4} from 'uuid';
import cities from "../static/cities.json";
import states from "../static/states.json";

export function signIn(email: string, password: string) {
    return async function (dispatch, getState) {
        await Auth.signIn({
            username: email,
            password: password
        });

        return dispatch(signInApiCall(email));
    }
}

export function signInApiCall(email: string) {
    return async function (dispatch, getState) {
        const data: AxiosResponse = await unAuthenticatedAxios.get("users/search/findUserByEmail/?email=" + email);
        return dispatch(setUser(data.data));
    }
}

export function signOut() {
    return async function (dispatch, getState) {
        await Auth.signOut();
        return dispatch(setUser(null));
    }
}

export function postResourceRequest(data: AvailabilityData, resourceType: ResourceType) {
    return async function (dispatch, getState) {
        await authenticatedAxios.post("resources/", {
            ...data,
            id: uuidv4(),
            userId: getState().rootReducer.user.id,
            resourceType: ResourceType[resourceType]
        });
        if (resourceType == ResourceType.AVAILABILITY) {
            return dispatch(getAvailabilityData());
        } else {
            return dispatch(getRequirementsData());
        }
    }
}

export function deleteResource(id: string, resourceType: ResourceType) {
    return async function (dispatch, getState) {
        await authenticatedAxios.delete(`resources/${id}`);

        if (resourceType == ResourceType.AVAILABILITY) {
            const availabilityData: Array<AvailabilityData> = getState().rootReducer.availabilityData;
            return dispatch(setAvailabilityData(availabilityData.filter(data => data.id !== id)));
        } else {
            const requirementData: Array<RequirementData> = getState().rootReducer.requirementData;
            return dispatch(setRequirementsData(requirementData.filter(data => data.id !== id)));
        }
    }
}

export function getAvailabilityData() {
    return async function (dispatch, getState) {
        const data: AxiosResponse = await unAuthenticatedAxios.get("resources/search/findAllByResourceType/?type=AVAILABILITY");
        const resources: AvailabilityData[] = data.data._embedded.resources;
        getDataForResources(resources, dispatch);
        return dispatch(setAvailabilityData(data.data._embedded.resources));
    }
}

export function getRequirementsData() {
    return async function (dispatch, getState) {
        const data: AxiosResponse = await unAuthenticatedAxios.get("resources/search/findAllByResourceType/?type=REQUIREMENT");
        const resources: RequirementData[] = data.data._embedded.resources;
        getDataForResources(resources, dispatch);
        return dispatch(setRequirementsData(resources));
    }
}

function getDataForResources(resources: AvailabilityData[], dispatch: any) {
    for (let resource of resources) {
        dispatch(getUserData(resource.userId));
    }
}

function getUserData(userId: string) {
    return async function (dispatch, getState) {
        const data: AxiosResponse = await unAuthenticatedAxios.get("/users/" + userId);
        return dispatch(setUserData(userId, data.data));
    }
}

export function getAllCities() {
    return async function (dispatch, getState) {
        const citiesData: Array<City> = cities;
        citiesData.forEach(cityData => {
            dispatch(setCityData(cityData.id, cityData));
        });
    }
}

export function getAllStates() {
    return async function (dispatch, getState) {
        const statesData: StateData[] = states;
        statesData.forEach(stateData => {
            dispatch(setStateData(stateData.id, stateData));
        });
    }
}
