import React from "react";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import AvailabilityPage from "./pages/AvailabilityPage";
import RequirementsPage from "./pages/RequirementsPage";
import MyAvailabilityPage from "./pages/MyAvailabilityPage";
import MyRequirementsPage from "./pages/MyRequirementsPage";
import {
    getAllCities,
    getAllStates,
    getAvailabilityData,
    getRequirementsData,
    signInApiCall
} from "./constants/backend_api_action";
import {connect} from "react-redux";
import {getCurrentUserInfo} from "./constants/utilityFunctions";
import LoadingComponent from "./components/LoadingComponent";
import {Route, withRouter} from "react-router-dom";
import HowToUseDialog from "./components/HowToUseDialog";
import {AvailabilityData, RequirementData} from "./constants/DataInterfaces";
import SearchOnSocialMedia from "./pages/SearchOnSocialMedia";


interface Props {
    history: any;

    signInApiCall: (email: string) => Promise<void>;
    getAllCities: () => Promise<void>;
    getAllStates: () => Promise<void>;
    getAvailabilityData: () => Promise<AvailabilityData[]>;
    getRequirementsData: () => Promise<RequirementData[]>;
}

interface State {
    loading: boolean;
}

class App extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    async componentDidMount() {
        try {
            await Promise.all([this.props.getAllCities(), this.props.getAllStates()]);
            const userInfo = await getCurrentUserInfo();
            await this.props.signInApiCall(userInfo.userId);
            if (window.location.pathname.indexOf("/login") !== -1 || window.location.pathname.indexOf("/signup") !== -1) {
                this.props.history.push({
                    pathname: "/"
                });
            }
        } catch (error) {
            console.log(error);
        }
        this.setState({
            loading: false
        });
        this.props.getAvailabilityData();
        this.props.getRequirementsData();
    }

    render() {
        if (this.state.loading) {
            return <LoadingComponent showLoading={this.state.loading}/>
        }

        return (
            <div>
                <HowToUseDialog/>
                <Route path="/login/" exact component={SignIn}/>
                <Route path="/signup/" exact component={SignUp}/>
                <Route path="/" exact component={AvailabilityPage}/>
                <Route path="/availability/" exact component={AvailabilityPage}/>
                <Route path="/search-on-social-media/" exact component={SearchOnSocialMedia}/>
                <Route path="/requirements/" exact component={RequirementsPage}/>
                <Route path="/my-availability/" exact component={MyAvailabilityPage}/>
                <Route path="/my-requirements/" exact component={MyRequirementsPage}/>
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        signInApiCall: (email: string) => {
            return dispatch(signInApiCall(email));
        },
        getAllCities: () => {
            return dispatch(getAllCities());
        },
        getAllStates: () => {
            return dispatch(getAllStates());
        },
        getRequirementsData: () => {
            return dispatch(getRequirementsData());
        },
        getAvailabilityData: () => {
            return dispatch(getAvailabilityData());
        }
    };
}

export default connect(null, mapDispatchToProps)(withRouter(App));
