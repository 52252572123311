import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {Auth} from "aws-amplify";
import {withStyles} from "@material-ui/core";
import {matchPasswords, validateEmail, validatePassword, validatePhoneNumber} from "../constants/utilityFunctions";
import LoadingComponent from "../components/LoadingComponent";
import {v4 as uuidv4} from 'uuid';
import {signUpUser} from "../constants/backend_api";
import {SignUpDetails} from "../constants/DataInterfaces";
import {signIn} from "../constants/backend_api_action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import CollapsibleAppBar from "../components/CollapsibleAppBar";

interface Props {
    classes: any;
    history: any;

    signIn: (email: string, password: string) => Promise<void>;
}

interface State {
    loading: boolean;
    email: string;
    phoneNumber: number;
    password: string;
    confirmPassword: string;
}

const styles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class SignUp extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            loading: false,
            phoneNumber: null,
            password: '',
            confirmPassword: ''
        };

        this.submitForm = this.submitForm.bind(this);
    }

    async submitForm(event) {
        event.preventDefault();
        if (!(validateEmail(this.state.email) && validatePassword(this.state.password) &&
            validatePhoneNumber(this.state.phoneNumber) && matchPasswords(this.state.password, this.state.confirmPassword))) {
            return;
        }
        this.setState({
            loading: true
        });

        try {
            await Auth.signUp({
                username: this.state.email,
                attributes: {
                    email: this.state.email,
                },
                password: this.state.password
            });

            const signUpDetails: SignUpDetails = {
                id: uuidv4(),
                email: this.state.email,
                phoneNumber: this.state.phoneNumber
            };

            await signUpUser(signUpDetails);

            await this.props.signIn(this.state.email, this.state.password);
            this.props.history.push({
                pathname: "/"
            });
        } catch (error) {
            console.log(error);
            alert(error);
        }

        this.setState({
            loading: false
        });
    }

    render() {
        const {classes} = this.props;

        return (
            <CollapsibleAppBar title={"SignUp"}>
                <Container component="main" maxWidth="xs">
                    <LoadingComponent showLoading={this.state.loading}/>
                    <CssBaseline/>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign up
                        </Typography>
                        <form className={classes.form} onSubmit={this.submitForm}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        onChange={event => {
                                            this.setState({
                                                phoneNumber: parseInt(event.target.value)
                                            });
                                        }}
                                        type={"number"}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="phoneNumber"
                                        label="Phone Number"
                                        name="phoneNumber"
                                        autoComplete="tel-national"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        onChange={event => {
                                            this.setState({
                                                email: event.target.value
                                            });
                                        }}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        onChange={event => {
                                            this.setState({
                                                password: event.target.value
                                            });
                                        }}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        onChange={event => {
                                            this.setState({
                                                confirmPassword: event.target.value
                                            });
                                        }}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        type="password"
                                        id="confirmPassword"
                                        autoComplete="current-password"
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type={"submit"}
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Sign Up
                            </Button>
                            <Grid container justify="flex-end">
                                <Grid item>
                                    <Link href="/login/" variant="body2">
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Container>
            </CollapsibleAppBar>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (email: string, password: string) => {
            return dispatch(signIn(email, password));
        }
    };
}

export default connect(null, mapDispatchToProps)(withStyles(styles as any)(withRouter(SignUp)));
