import {AvailabilityData, City, RequirementData, StateData, User} from "../constants/DataInterfaces";

export const SET_AVAILABILITY_DATA: string = "SET_AVAILABILITY_DATA";
export const SET_REQUIREMENTS_DATA: string = "SET_REQUIREMENTS_DATA";
export const SET_USER: string = "SET_USER";
export const SET_USER_DATA: string = "SET_USER_DATA";
export const SET_CITY_DATA: string = "SET_CITY_DATA";
export const SET_STATE_DATA: string = "SET_STATE_DATA";

export function setAvailabilityData(availabilityData: AvailabilityData[]) {
    return {
        type: SET_AVAILABILITY_DATA,
        payload: availabilityData
    };
}

export function setRequirementsData(requirementsData: RequirementData[]) {
    return {
        type: SET_REQUIREMENTS_DATA,
        payload: requirementsData
    };
}

export function setUser(user: User) {
    return {
        type: SET_USER,
        payload: user
    };
}

export function setUserData(userId: string, userData: User) {
    return {
        type: SET_USER_DATA,
        payload: {
            userId: userId,
            userData: userData
        }
    };
}

export function setCityData(cityId: number, cityData: City) {
    return {
        type: SET_CITY_DATA,
        payload: {
            cityId: cityId,
            cityData: cityData
        }
    };
}

export function setStateData(stateId: number, stateData: StateData) {
    return {
        type: SET_STATE_DATA,
        payload: {
            stateId: stateId,
            stateData: stateData
        }
    };
}
