import React from "react";
import {SideBarOption, User} from "../constants/DataInterfaces";
import {getSideBarOptions} from "../constants/utilityFunctions";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

interface Props {
    user: User;
    history: any;
}

export default class SideMenuBar extends React.Component<Props, any> {
    render() {
        const sideBarOptions: SideBarOption[] = getSideBarOptions(this.props.user != null);
        return (
            <div>
                <Toolbar/>
                <Divider/>
                <List>
                    {sideBarOptions.map((sideBarOption: SideBarOption) => {
                        const isCurrentPage: boolean = window.location.pathname.length <= 1
                            ? sideBarOption.route === "/availability/"
                            : sideBarOption.route.indexOf(window.location.pathname) > -1 ||
                            window.location.pathname.indexOf(sideBarOption.route) > -1;
                        return (
                            <ListItem
                                onClick={() => {
                                    if (sideBarOption.active && !isCurrentPage) {
                                        this.props.history.push({
                                            pathname: sideBarOption.route
                                        });
                                    }
                                }}
                                selected={isCurrentPage}
                                button key={sideBarOption.title}>
                                <ListItemText primary={sideBarOption.title}/>
                            </ListItem>
                        );
                    })}
                </List>
                <Divider/>
                <List>
                </List>
            </div>
        );
    }
}